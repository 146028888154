<template>
  <div class="content-container">
    <PageTitle :title="$t('EXTRA_GAMES.TITLE')" class="page-title" />
    <h1 class="page-section-title section-title">
      {{ $t("CREATE_EXTRA_GAME.NEW") }}
    </h1>
    <BackButton
      :text="$t('BACK_OVERVIEW')"
      :route-name="ROUTE_NAMES_CMS.EXTRAS"
    />
    <FormWrapper
      v-if="originalData"
      :send-form-data="validateData"
      class="page-form"
      :on-form-data-update="updatedForm"
    >
      <template #form-content>
        <!-- Title -->
        <InputField
          :field-title="$t('EXTRA_GAMES.TITLE')"
          :field-name="'extraName'"
          :rules="'required'"
          :type="'text'"
          :placeholder="$t('EXTRA_GAMES.NEW_TITLE')"
          :cy-selector="'name-input'"
          :input-icon-error="require('../../../assets/icons/icn_error.svg')"
          :api-error-text="''"
          :value="originalData.name"
          class="form-element"
        />

        <!-- Description -->
        <RichTextField :field-title="$t('EXTRA_GAMES.DESCRIPTION')"
                       :input-callback="descriptionInput"
                       :error-text="richTextError"
                       :placeholder="$t('EXTRA_GAMES.NEW_DESCRIPTION')"
                       :value="originalData.description"
        />

        <!-- Partner Link -->
        <InputField
          :field-title="$t('EXTRA_GAMES.PARTNER_LINK')"
          :field-name="'extraLink'"
          :rules="'required'"
          :type="'text'"
          :placeholder="$t('EXTRA_GAMES.EXAMPLE_LINK')"
          :cy-selector="'name-input'"
          :input-icon-error="require('../../../assets/icons/icn_error.svg')"
          :api-error-text="urlErrorText"
          :value="originalData.link"
          class="form-element"
        />
        <h3>{{ $t("CREATE_PRIZE.COVER_PHOTO") }}</h3>

        <ImageInput :start-file="originalData ? originalData.mediaFileUrl : null"
                    :media-files="mediaFiles ?? []"
                    :error-text="''"
                    @file="setImage" />


        <!-- Bits Awarded -->
        <div class="form-element-bits">
          <InputField
            :field-title="$t('EXTRA_GAMES.BITS_AWARDED')"
            :field-name="'bitsAwarded'"
            :rules="'required'"
            :type="'number'"
            :field-width="'27%'"
            :placeholder="0"
            :cy-selector="'name-input'"
            :input-icon-error="require('../../../assets/icons/icn_error.svg')"
            :api-error-text="''"
            :value="originalData.bitsAwarded"
            class="form-element"
          />
        </div>

        <TagSelector
          v-if="originalData.tags"
          ref="tagSelector"
          :field-title="$t('EXTRA_GAMES.TAGS')"
          :field-name="'tags'"
          :rules="'required'"
          :options="tagData"
          :update-parent="updateTags"
          :pre-selected="selectedTags"
          :error-text="tagSelectorError"
        />
      </template>

      <template #button-submit>
        <button type="submit" class="pink-button submit-button">
          {{ $t("SAVE") }}
        </button>
      </template>
    </FormWrapper>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import BackButton from '@/components/elements/BackButton'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import InputField from '@/components/yo-form/InputField'
import FormWrapper from '@/components/yo-form/FormWrapper'
import ImageInput from '@/components/elements/inputFields/ImageInput'

import ModalController, {
  MODAL_NAMES
} from '@/utils/helpers/basicModal/ModalController'
import router from '@/router'
import { useRoute, useRouter } from 'vue-router'
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import RichTextField from '@/components/elements/RichTextEditor/RichTextField'
import TagSelector from '@/components/yo-form/TagSelector'
import nl from '@/utils/language/nl.json'
import { GET_TAGS, EDIT_EXTRA_GAME } from '@/store/modules/cms/actions'
import ApiHandler from '@/utils/handlers/ApiHandler'

export default {
  name: 'CreateExtraGame',
  components: {
    PageTitle,
    BackButton,
    InputField,
    FormWrapper,
    TagSelector,
    RichTextField,
    ImageInput,
  },
  setup() {
    const originalData = ref(undefined)
    const route = useRoute()
    const store = useStore()
    const currentFormData = ref({})
    const mediaFiles = ref([])
    const allowedMimes = computed(() => ['image/png', 'image/jpeg', 'image/webp', 'image/avif'])
    const modalController = ref(new ModalController())
    const activeModal = computed(() => store.getters.getActiveBasicModal)
    const isBasicVersionDisplayed = computed(
      () => activeModal.value === MODAL_NAMES.CREATE_ATTACHMENT
    )


    const image = ref(null)
    function setImage(data) {
      image.value = data
    }


    const extraGameId = route.params.id



    const tagData = computed(() => store.getters.getTags)
    const selectedFile = ref()
    const selectedTags = ref(null)
    const tagSelectorError = ref('')

    ApiHandler.get('cms/extra-games/get-extra-game/' + extraGameId).then(
      (response) => {
        originalData.value = response.data
        setImage(response.data.mediaFileId)
        descriptionValue.value = response.data.description
        selectedTags.value = response.data.tags.map(x => x.id)
      }
    )

    const descriptionValue = ref('')
    const richTextError = ref('')
    const urlErrorText = ref('')

    getTags()
    function getTags() {
      return store.dispatch(GET_TAGS, {})
    }

    function updateTags(selected) {
      selectedTags.value = selected
    }

    function validateData(data) {
      data.description = descriptionValue.value
      if (!data.description || data.description === '') {
        richTextError.value = nl.CREATE_EXTRA_GAME.NO_DESCRIPTION_GIVEN
        return false
      }
      data.mediaFile = image.value
      if (!data.mediaFile) {
        return false
      }
      // URL validity
      if (!/https?:\/\/?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g.test(data.extraLink)) {
        urlErrorText.value = 'De ingevulde link is niet geldig. Zorg ervoor dat het begint met \'https://\''
        return false
      }
      data.tags = selectedTags.value
      if (!selectedTags.value || selectedTags.value === '' || selectedTags.value.length === 0) {
        tagSelectorError.value = nl.CREATE_EXTRA_GAME.NO_TAGS_GIVEN
        return false
      }
      urlErrorText.value = ''
      data.name = data.extraName
      data.link = data.extraLink
      data.id = extraGameId
      store.dispatch(EDIT_EXTRA_GAME, data).then((result) => {
        if (result) {
          router.push({ name: ROUTE_NAMES_CMS.EXTRAS })
        }
      })
    }

    function descriptionInput(value) {
      descriptionValue.value = value
    }



    function updatedForm(data) {
      currentFormData.value = data
    }


    return {
      originalData,
      ROUTE_NAMES_CMS,
      isBasicVersionDisplayed,
      tagData,
      updateTags,
      validateData,
      allowedMimes,
      selectedFile,
      selectedTags,
      richTextError,
      tagSelectorError,
      descriptionInput,
      updatedForm,
      urlErrorText,
      setImage,
      mediaFiles
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.cover-photo-section {
  margin-bottom: rem(24);
  button {
    background-color: white;
  }
  .file-image-preview.big {
    width: rem(350);
    height: rem(240);
  }

  h3 {
    color: var(--blue_dark);
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.1875rem;
    margin-bottom: 0.5rem;
    text-align: left;
  }
}

.form-element-bits {
  margin-bottom: rem(24);
}
</style>
